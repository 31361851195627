import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, DateTimeInput, FunctionField, useDataProvider, RichTextField, useGetOne, HttpError, useRefresh, DateField, DateInput } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Typography, Chip, dividerClasses } from '@mui/material';
import { AllImageField_Rich, PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { RichTextInput } from 'ra-input-rich-text';
import { MoneyField } from './custom/MoneyField';
import { apiUrl } from '../../global';
import { httpClient } from '../dataProvider';
import qs from 'qs';
import { MRichTextInput } from './custom/MRichTextInput';
//import ResetOrderNum from './PartnerServiceChart_ResetOrderNum';

import { useQuery, useMutation } from 'react-query';

{/* 
  import { PartnerServiceChartList, PartnerServiceChartCreate, PartnerServiceChartEdit, PartnerServiceChartShow } from './components/PartnerServiceChart';

  <Resource name="CMSPartnerServiceChart" list={PartnerServiceChartList} create={PartnerServiceChartCreate} edit={PartnerServiceChartEdit} show={PartnerServiceChartShow} />
  <Resource name="CMSPartnerServiceChart" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'PartnerServiceChart',
  },
  table: {
    id: 'id',
    P_PayNumber: '流水号',
    P_PayStyle: '支付形式',
    P_PayPrice: '会费金额',
    P_PayPriceM: '会费金额',
    P_PayTime: '登记时间',
    P_PayPartnerID: 'P_PayPartnerID',
    P_PayStartTime: 'P_PayStartTime',
    P_PayEndTime: '到期时间',
    P_HuiLv: 'P_HuiLv',
    P_IsPay: '开启',
    OrderNum: 'OrderNum',
    UpTime: 'UpTime',
    A: '备注',
    B: '附件',
    C: '已付',
    D: 'D',
    E: 'E',
  }
};

export const GetFWSShowName = ({ mobile }: any) => {
  const { data, isLoading, isError, error } = useQuery(['GetFWSShowName', mobile], () =>
    httpClient(`${apiUrl}/Admin/CMSPartnerServiceChart/GetFWSShowName`, {
      method: 'POST', contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ mobile })
    }).then(({ json }) => {
      if (json.Code == 1) return json.Result || 'null';
    })
  );
  if (isLoading) return <div>loading</div>
  if (isError) return <div>{`ERR:${error}`}</div>
  return <Stack>{data}</Stack>
}
export const GetRealName = ({ mid, readonly = false }: any) => {
  const { data, isLoading, isError, error } = useQuery(['GetRealName', mid], () =>
    httpClient(`${apiUrl}/Admin/CMSPartnerServiceChart/GetRealName`, {
      method: 'POST', contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ mid })
    }).then(({ json }) => {
      if (json.Code == 1) return json.Result || 'null';
    })
  );
  if (isLoading) return <div>loading</div>
  if (isError) return <div>{`ERR:${error}`}</div>
  if (readonly) return <div>{data}</div>
  return <EditRealName mid={mid} realName={data}>{data}</EditRealName>
}
export const EditRealName = ({ mid, realName, children }: any) => {
  const refresh = useRefresh();
  const open = () => {
    var _realName = prompt("请输入姓名", realName);
    if (_realName) {
      saveReallName.mutate({ mid, realName: _realName })
    }
  }
  const saveReallName = useMutation(({ mid, realName }: any) =>
    httpClient(`${apiUrl}/Admin/CMSPartnerServiceChart/SaveRealName`, {
      method: 'POST', contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ mid, realName })
    }).then(({ json }) => {
      if (json.Code != 1) throw new HttpError(`${json.Meessage}`, 500);
    }), {
    onSuccess: () => { refresh() },
    onError: (err) => { alert(`${err}`) }
  })
  return <Stack onClick={open} sx={{ color: ({ palette: { primary: { main } } }) => main }}> {children}</Stack >
}
export const EditNickName = ({ mid, nickName, children }: any) => {
  const refresh = useRefresh();
  const open = () => {
    var _nickName = prompt("请输入昵称", nickName);
    if (_nickName) {
      saveNickName.mutate({ mid, nickName: _nickName })
    }
  }
  const saveNickName = useMutation(({ mid, nickName }: any) =>
    httpClient(`${apiUrl}/Admin/CMSPartnerServiceChart/SaveNickName`, {
      method: 'POST', contentType: 'application/x-www-form-urlencoded',
      body: qs.stringify({ mid, nickName })
    }).then(({ json }) => {
      if (json.Code != 1) throw new HttpError(`${json.Meessage}`, 500);
    }), {
    onSuccess: () => { refresh() },
    onError: (err) => { alert(`${err}`) }
  })
  return <Stack direction={'row'} onClick={open} sx={{ color: ({ palette: { primary: { main } } }) => main }}> {children}</Stack >
}

//分页列表页面
export const PartnerServiceChartList = () => {
  const redirect = useRedirect();//奇怪问题：这句必须保留，去掉后下面Filters里的setFilters({}, displayedFilters);会失效
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton /><br />
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'P_PayTime', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <FunctionField label={useTxtData.table.P_PayNumber} render={({ P_PayPrice, C }: any) => {
          const wf = (parseFloat(P_PayPrice) || 0) - (parseFloat(C) || 0);
          return (<><TextField label={useTxtData.table.P_PayNumber} source="P_PayNumber" />
            {wf > 0 && <><br /><Chip color='warning' label={`未付：${wf.toLocaleString('en-US')}`} size="small" /></>}
          </>
          )
        }} />

        {/* <TextField label={useTxtData.table.P_PayPartnerID} source="P_PayPartnerID" /> */}
        <FunctionField label='服务商' render={({ P_PayPartnerID }: any) => <>
          <ReferenceField source="P_PayPartnerID" reference="Admin/CMSMember" link={false} sortable={false}>
            <Stack direction={'row'}>
              <TextField source="M_MemberName" />
              <FunctionField render={({ id, M_NickName }: any) => <EditNickName mid={id} nickName={M_NickName} >(<TextField source="M_NickName" />)</EditNickName>} />
            </Stack>
          </ReferenceField>
          <GetRealName mid={P_PayPartnerID} />
        </>} />


        <TextField label={useTxtData.table.P_PayStyle} source="P_PayStyle" sortable={false} />
        <MoneyField label={useTxtData.table.P_PayPrice} source="P_PayPrice" sortable={false} />
        <MoneyField style={{ color: 'green' }} label={useTxtData.table.C} source="C" sortable={false} />

        {/* <TextField label={useTxtData.table.P_PayPriceM} source="P_PayPriceM" /> */}
        <DateField label={useTxtData.table.P_PayTime} source="P_PayTime" sortable={false} />
        {/* <TextField label={useTxtData.table.P_PayStartTime} source="P_PayStartTime" /> */}
        <DateField label={useTxtData.table.P_PayEndTime} source="P_PayEndTime" sortable={false} />
        {/* <TextField label={useTxtData.table.P_HuiLv} source="P_HuiLv" /> */}
        <BooleanField label={useTxtData.table.P_IsPay} source="P_IsPay" />
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.UpTime} source="UpTime" /> */}

        <AllImageField_Rich label={useTxtData.table.B} source="B" sortable={false} />
        <TextField label={useTxtData.table.A} source="A" sortable={false} />
        {/*
        <TextField label={useTxtData.table.C} source="C" />
        <TextField label={useTxtData.table.D} source="D" />
        <TextField label={useTxtData.table.E} source="E" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const PartnerServiceChartShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        <Stack sx={{ mb: 2 }}>
          <ReferenceField label='服务商' source="P_PayPartnerID" reference="Admin/CMSMember" link={false} sortable={false}>
            <Stack direction={'row'} sx={{}}><Typography variant='body2'>总服务商：</Typography><TextField source="M_MemberName" />(<TextField source="M_NickName" />)</Stack>
          </ReferenceField>
          <FunctionField label='服务商' render={({ P_PayPartnerID }: any) => <Stack direction={'row'}>
            姓名：<GetRealName mid={P_PayPartnerID} />
          </Stack>} />
        </Stack>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <TextField label={useTxtData.table.P_PayNumber} source="P_PayNumber" />
        <TextField label={useTxtData.table.P_PayStyle} source="P_PayStyle" />
        <TextField label={useTxtData.table.P_PayPrice} source="P_PayPrice" />
        <TextField label={useTxtData.table.C} source="C" />
        {/* <TextField label={useTxtData.table.P_PayPriceM} source="P_PayPriceM" /> */}
        <DateField label={useTxtData.table.P_PayTime} source="P_PayTime" />
        {/* <TextField label={useTxtData.table.P_PayPartnerID} source="P_PayPartnerID" /> */}
        {/* <TextField label={useTxtData.table.P_PayStartTime} source="P_PayStartTime" /> */}
        <DateField label={useTxtData.table.P_PayEndTime} source="P_PayEndTime" />
        {/* <TextField label={useTxtData.table.P_HuiLv} source="P_HuiLv" /> */}
        <BooleanField label={useTxtData.table.P_IsPay} source="P_IsPay" />
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.UpTime} source="UpTime" /> */}
        <TextField label={useTxtData.table.A} source="A" />
        <AllImageField_Rich label={useTxtData.table.B} source="B" sortable={false} sx={{ 'img': { height: 120 } }} />

        {/* <TextField label={useTxtData.table.D} source="D" /> */}
        {/* <TextField label={useTxtData.table.E} source="E" /> */}
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const PartnerServiceChartEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const PartnerServiceChartCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.P_PayNumber} source="P_PayNumber" validate={[required()]} /> */}
      {Edit &&
        <Stack sx={{ mb: 2 }}>
          <ReferenceField label='服务商' source="P_PayPartnerID" reference="Admin/CMSMember" link={false} sortable={false}>
            <Stack direction={'row'} sx={{}}><Typography variant='body2'>总服务商：</Typography><TextField source="M_MemberName" />(<TextField source="M_NickName" />)</Stack>
          </ReferenceField>
          <FunctionField label='服务商' render={({ P_PayPartnerID }: any) => <Stack direction={'row'}>
            姓名：<GetRealName mid={P_PayPartnerID} />
          </Stack>} />
        </Stack>
      }

      <SelectInput label={useTxtData.table.P_PayStyle} source="P_PayStyle" validate={[required()]} disabled={Edit} choices={[
        { id: '线下付款', name: '线下付款' },
        { id: '试用', name: '试用' }
      ]} />

      <Stack direction={'row'}>
        <NumberInput label={useTxtData.table.P_PayPrice} source="P_PayPrice" validate={[required()]} sx={{ mr: 1 }} />
        <NumberInput label={useTxtData.table.C} source="C" validate={[required()]} />
      </Stack>
      {/* <TextInput label={useTxtData.table.P_PayPriceM} source="P_PayPriceM" validate={[required()]} /> */}
      <Stack direction={'row'}>
        <DateInput label={useTxtData.table.P_PayTime} source="P_PayTime" validate={[required()]} sx={{ mr: 1 }} />
        {/* <TextInput label={useTxtData.table.P_PayPartnerID} source="P_PayPartnerID" validate={[required()]} /> */}
        {/* <TextInput label={useTxtData.table.P_PayStartTime} source="P_PayStartTime" validate={[required()]} /> */}
        <DateInput label={useTxtData.table.P_PayEndTime} source="P_PayEndTime" validate={[required()]} />
      </Stack>
      {/* <TextInput label={useTxtData.table.P_HuiLv} source="P_HuiLv" validate={[required()]} /> */}
      <BooleanInput label={useTxtData.table.P_IsPay} source="P_IsPay" validate={[required()]} defaultValue={true} />
      {/* <TextInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.UpTime} source="UpTime" validate={[required()]} /> */}
      <TextInput multiline fullWidth label={useTxtData.table.A} source="A" validate={[]} />
      <MRichTextInput fullWidth label={useTxtData.table.B} source="B" validate={[]} />
      {/* <TextInput label={useTxtData.table.D} source="D" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.E} source="E" validate={[required()]} /> */}
    </SimpleForm>
  );
}