import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { EditorOptions } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { RichTextInput } from 'ra-input-rich-text';
import { Node } from '@tiptap/core'
import { Plugin, PluginKey, } from '@tiptap/pm/state'

const PasteAndDropImage = Node.create({
    name: 'PasteAndDropImage',
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('codePasteImage'),
                props: {
                    handlePaste: (view, event) => { //复制图片到编辑器里面自动转成base64，不用这个代码，把Image的allowBase64设置为true就行了
                        const _allowedMimeTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
                        const _editor = this.editor;
                        const file = event.clipboardData?.files[0];
                        if (file) {
                            console.log('handlePaste', file)
                            const { name, type } = file;
                            if (_allowedMimeTypes.indexOf(type.toLocaleLowerCase()) < 0) return true;

                            const fileReader = new FileReader()
                            fileReader.readAsDataURL(file);
                            fileReader.onload = () => {
                                _editor.chain().insertContentAt(_editor.state.selection.anchor, {
                                    type: 'image',
                                    attrs: {
                                        src: fileReader.result,
                                    },
                                }).focus().run()
                            }
                            return true;
                        }
                    },
                    handleDrop: (view, event, slice, moved) => { //托拽图片到编辑器里面
                        const _allowedMimeTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
                        const _editor = this.editor;
                        const file = event.dataTransfer?.files[0];
                        if (file) {
                            // console.log('handleDrop', file)
                            const { name, type } = file;
                            if (_allowedMimeTypes.indexOf(type.toLocaleLowerCase()) < 0) return true;

                            const fileReader = new FileReader()
                            fileReader.readAsDataURL(file)
                            fileReader.onload = () => {
                                _editor.chain().insertContentAt(_editor.state.selection.anchor, {
                                    type: 'image',
                                    attrs: {
                                        src: fileReader.result,
                                    },
                                }).focus().run()
                            }
                            return true;
                        }
                    },
                }
            })
        ]
    }
});

export const MRichTextInput = (props: any) => {
    const _EditorOptions: Partial<EditorOptions> = {
        extensions: [
            PasteAndDropImage,
            StarterKit,
            Underline,
            Link,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Image.configure({
                inline: true,
                allowBase64: true,
            }),
            TextStyle, // Required by Color
            Color,
            Highlight.configure({ multicolor: true }),
        ],
    };

    return <RichTextInput {...props} editorOptions={_EditorOptions} />
}