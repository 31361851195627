import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Typography } from '@mui/material';
import { AllImageField, FirstImageField, PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { BaseUrl } from '../../global';
import { EditNickName, EditRealName, GetRealName } from './PartnerServiceChart';
import { MobileDateRangePicker } from '@mui/lab';
import { RichTextInput } from 'ra-input-rich-text';
import { MRichTextInput } from './custom/MRichTextInput';
//import ResetOrderNum from './MemberZDconfig_ResetOrderNum';

{/* 
  import { MemberZDconfigList, MemberZDconfigCreate, MemberZDconfigEdit, MemberZDconfigShow } from './components/MemberZDconfig';

  <Resource name="CMSMemberZDconfig" list={MemberZDconfigList} create={MemberZDconfigCreate} edit={MemberZDconfigEdit} show={MemberZDconfigShow} />
  <Resource name="CMSMemberZDconfig" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'MemberZDconfig',
  },
  table: {
    id: 'id',
    M_MID: '服务商',
    M_BrandDrug: '品牌药',
    M_UnBrandDrug: '仿制药',
    M_CBBS: '成本倍数',
    M_RealName: '真实名称',
    Sheng: '省',
    Shi: '市',
    Qu: '区',
    M_AcceptEmail: 'M_AcceptEmail',
    M_IDcardImg: '证件',
    M_Remarks: '备注',
    M_BachelorName: 'M_BachelorName',
    M_YBHSL: '预保护数',
    M_GLAcount: 'M_GLAcount',
    M_IsDaoChu: 'M_IsDaoChu',
    OrderNum: 'OrderNum',
    M_RealName_BY: 'M_RealName_BY',
    M_AcceptEmail_BY: 'M_AcceptEmail_BY',
    M_Phone_BY: 'M_Phone_BY',
    UpTime: '更新时间',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
  }
};

//分页列表页面
export const MemberZDconfigList = () => {
  const redirect = useRedirect();//奇怪问题：这句必须保留，去掉后下面Filters里的setFilters({}, displayedFilters);会失效
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton /><br />
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'UpTime', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" sortable={false} />

        <ReferenceField label='服务商' source="M_MID" reference="Admin/CMSMember" link={false}>
          <TextField source="M_MemberName" />
          <FunctionField render={({ id, M_NickName }: any) => <EditNickName mid={id} nickName={M_NickName} >(<TextField source="M_NickName" />)</EditNickName>} />
        </ReferenceField>
        {/* <TextField label={useTxtData.table.M_MID} source="M_MID" /> */}

        <FunctionField label='扣点' render={({ M_BrandDrug, M_UnBrandDrug }: any) => <>
          {M_BrandDrug && `品牌：${M_BrandDrug * 100}%`}<br />
          {M_UnBrandDrug && `仿制：${M_UnBrandDrug * 100}%`}
        </>} />

        <FunctionField label='成本/保护' render={({ M_BrandDrug, M_UnBrandDrug }: any) => <>
          成本倍数：<TextField label={useTxtData.table.M_CBBS} source="M_CBBS" /><br />
          预保护数：<TextField label={useTxtData.table.M_YBHSL} source="M_YBHSL" />
        </>} />

        {/* <TextField label={useTxtData.table.M_RealName} source="M_RealName" sortable={false} /> */}
        <FunctionField label={useTxtData.table.M_RealName} render={({ M_MID, M_RealName }: any) => <>
          <EditRealName mid={M_MID} realName={M_RealName}>{M_RealName}</EditRealName>
        </>} />

        {/* <TextField label={useTxtData.table.Sheng} source="Sheng" /> */}
        {/* <TextField label={useTxtData.table.Shi} source="Shi" /> */}
        {/* <TextField label={useTxtData.table.Qu} source="Qu" /> */}
        {/* <TextField label={useTxtData.table.M_AcceptEmail} source="M_AcceptEmail" /> */}
        {/* <TextField label={useTxtData.table.M_IDcardImg} source="M_IDcardImg" /> */}
        {/* <FunctionField label='证件' render={({ M_IDcardImg = '' }: any) => {
          let _IDS = M_IDcardImg.split(','); if (_IDS.length == 1 && _IDS[0] == '') _IDS = [];
          return <Stack direction={'row'} spacing={1}>{_IDS.map((_idImg: string) => <img style={{ width: 60, height: 60 }} src={BaseUrl + _idImg} />)}</Stack>
        }} /> */}
        <AllImageField label='证件' source='M_IDcardImg' subSource='src' />

        {/* <TextField label={useTxtData.table.M_Remarks} source="M_Remarks" /> */}
        {/* <TextField label={useTxtData.table.M_BachelorName} source="M_BachelorName" /> */}
        {/* <TextField label={useTxtData.table.M_GLAcount} source="M_GLAcount" /> */}
        {/* <TextField label={useTxtData.table.M_IsDaoChu} source="M_IsDaoChu" /> */}
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.M_RealName_BY} source="M_RealName_BY" /> */}
        {/* <TextField label={useTxtData.table.M_AcceptEmail_BY} source="M_AcceptEmail_BY" /> */}
        {/* <TextField label={useTxtData.table.M_Phone_BY} source="M_Phone_BY" /> */}
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        {/* <TextField label={useTxtData.table.A} source="A" /> */}
        {/* <TextField label={useTxtData.table.B} source="B" /> */}
        {/* <TextField label={useTxtData.table.C} source="C" /> */}
        {/* <TextField label={useTxtData.table.D} source="D" /> */}
        {/* <TextField label={useTxtData.table.E} source="E" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberZDconfigShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.M_MID} source="M_MID" />
        <TextField label={useTxtData.table.M_BrandDrug} source="M_BrandDrug" />
        <TextField label={useTxtData.table.M_UnBrandDrug} source="M_UnBrandDrug" />
        <TextField label={useTxtData.table.M_CBBS} source="M_CBBS" />
        <TextField label={useTxtData.table.M_RealName} source="M_RealName" />
        <TextField label={useTxtData.table.Sheng} source="Sheng" />
        <TextField label={useTxtData.table.Shi} source="Shi" />
        <TextField label={useTxtData.table.Qu} source="Qu" />
        <TextField label={useTxtData.table.M_AcceptEmail} source="M_AcceptEmail" />
        {/* <TextField label={useTxtData.table.M_IDcardImg} source="M_IDcardImg" /> */}
        <AllImageField label='证件' sx={{ 'img': { height: 120 } }} source='M_IDcardImg' subSource='src' />
        <TextField label={useTxtData.table.M_Remarks} source="M_Remarks" />
        <TextField label={useTxtData.table.M_BachelorName} source="M_BachelorName" />
        <TextField label={useTxtData.table.M_YBHSL} source="M_YBHSL" />
        <TextField label={useTxtData.table.M_GLAcount} source="M_GLAcount" />
        <TextField label={useTxtData.table.M_IsDaoChu} source="M_IsDaoChu" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.M_RealName_BY} source="M_RealName_BY" />
        <TextField label={useTxtData.table.M_AcceptEmail_BY} source="M_AcceptEmail_BY" />
        <TextField label={useTxtData.table.M_Phone_BY} source="M_Phone_BY" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        <TextField label={useTxtData.table.A} source="A" />
        <TextField label={useTxtData.table.B} source="B" />
        <TextField label={useTxtData.table.C} source="C" />
        <TextField label={useTxtData.table.D} source="D" />
        <TextField label={useTxtData.table.E} source="E" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberZDconfigEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />} mutationMode="optimistic">
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MemberZDconfigCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      < MyForm Create={true} toolbar={< PostCreateToolbar />} />
    </Create >
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}

      {Edit &&
        <Stack sx={{ mb: 2 }}>
          <ReferenceField label='服务商' source="M_MID" reference="Admin/CMSMember" link={false} sortable={false}>
            <Stack direction={'row'} sx={{}}><Typography variant='body2'>总服务商：</Typography><TextField source="M_MemberName" />(<TextField source="M_NickName" />)</Stack>
          </ReferenceField>
          <FunctionField label='服务商' render={({ M_MID }: any) => <Stack direction={'row'}>
            姓名：<GetRealName mid={M_MID} readonly={true} />
          </Stack>} />
        </Stack>
      }

      {/* <TextInput label={useTxtData.table.M_MID} source="M_MID" validate={[required()]} /> */}
      <Stack direction={'row'} spacing={1}>
        <div></div>
        <TextInput label={useTxtData.table.M_BrandDrug} source="M_BrandDrug" validate={[required()]} />
        <TextInput label={useTxtData.table.M_UnBrandDrug} source="M_UnBrandDrug" validate={[required()]} />
        <TextInput label={useTxtData.table.M_CBBS} source="M_CBBS" validate={[required()]} />
      </Stack>

      <Stack direction={'row'} spacing={1}>
        <div></div>
        <TextInput label={useTxtData.table.M_RealName} source="M_RealName" validate={[required()]} />
        <TextInput label={useTxtData.table.Sheng} source="Sheng" validate={[]} />
        <TextInput label={useTxtData.table.Shi} source="Shi" validate={[]} />
        <TextInput label={useTxtData.table.Qu} source="Qu" validate={[]} />
      </Stack>

      {/* <TextInput label={useTxtData.table.M_AcceptEmail} source="M_AcceptEmail" validate={[required()]} /> */}

      <UpLoadFile multiple label={useTxtData.table.M_IDcardImg} source="M_IDcardImg" validate={[]} />

      <MRichTextInput fullWidth label={useTxtData.table.M_Remarks} source="M_Remarks" validate={[]} />

      {/* <TextInput label={useTxtData.table.M_BachelorName} source="M_BachelorName" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_YBHSL} source="M_YBHSL" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_GLAcount} source="M_GLAcount" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_IsDaoChu} source="M_IsDaoChu" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_RealName_BY} source="M_RealName_BY" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_AcceptEmail_BY} source="M_AcceptEmail_BY" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.M_Phone_BY} source="M_Phone_BY" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.UpTime} source="UpTime" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.A} source="A" validate={[required()]} />
      <TextInput label={useTxtData.table.B} source="B" validate={[required()]} />
      <TextInput label={useTxtData.table.C} source="C" validate={[required()]} />
      <TextInput label={useTxtData.table.D} source="D" validate={[required()]} />
      <TextInput label={useTxtData.table.E} source="E" validate={[required()]} /> */}
    </SimpleForm>
  );
}