import { Admin, Resource, ListGuesser, CustomRoutes, usePermissions } from "react-admin";
import MyLogin from '../loginPage';
import authProvider from './authProvider';
import dataProvider from "./dataProvider";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from './chinese';
import { Route } from "react-router-dom";
import ChangePwd from "../customLayout/ChangePwd";
// import { AdminCreate, AdminList } from './components/Admin';

import MyLayout from "./customLayout/layout";
import Dashboard from "./components/dashboardPage";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';
import { MemberZDconfigList, MemberZDconfigCreate, MemberZDconfigEdit, MemberZDconfigShow } from './components/MemberZDconfig';
import { ZdMemberDiscountList, ZdMemberDiscountCreate, ZdMemberDiscountEdit, ZdMemberDiscountShow } from './components/zdMemberDiscount';
import { PartnerServiceChartList, PartnerServiceChartCreate, PartnerServiceChartEdit, PartnerServiceChartShow } from './components/PartnerServiceChart';
import { MarketPtrotectionList, MarketPtrotectionCreate, MarketPtrotectionEdit, MarketPtrotectionShow } from './components/MarketPtrotection';
import { HospitalShengList, HospitalShengCreate, HospitalShengEdit, HospitalShengShow } from './components/HospitalSheng';
import { HospitalCityList, HospitalCityCreate, HospitalCityEdit, HospitalCityShow } from './components/HospitalCity';

//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh', { allowMissing: true });

const App = () => {
    return (
        <Admin
            title="后台管理"
            loginPage={MyLogin}
            layout={MyLayout}
            dashboard={Dashboard}
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
        >
            {(permissions) => {
                console.log('permissions', permissions)
                let isAdmin = permissions === 'Admin';

                const adminList = isAdmin ? [

                    <Resource options={{ group: '会员', label: '会员列表', icon: <FormatListBulletedIcon /> }}
                        name="Admin/CMSMember" list={MemberList} show={MemberShow} />,
                    <Resource options={{ group: '会员', label: '总服务商', icon: <FormatListBulletedIcon /> }}
                        name="Admin/CMSMemberZDconfig" list={MemberZDconfigList}
                        //create={MemberZDconfigCreate} 
                        edit={MemberZDconfigEdit} show={MemberZDconfigShow} />,
                    <Resource options={{ group: '会员', label: '年费记录', icon: <FormatListBulletedIcon /> }}
                        name="Admin/CMSPartnerServiceChart" list={PartnerServiceChartList}
                        //create={PartnerServiceChartCreate}
                        edit={PartnerServiceChartEdit} show={PartnerServiceChartShow} />,



                    <Resource options={{ group: '市场', label: '市场保护', icon: <FormatListBulletedIcon /> }}
                        name="Admin/CMSMarketPtrotection" list={MarketPtrotectionList} create={MarketPtrotectionCreate} edit={MarketPtrotectionEdit} show={MarketPtrotectionShow} />,
                    // <Resource options={{ group: '市场', label: '省份', icon: <FormatListBulletedIcon /> }}
                    //     name="Admin/CMSHospitalSheng" list={HospitalShengList} create={HospitalShengCreate} edit={HospitalShengEdit} show={HospitalShengShow} />,
                    // <Resource options={{ group: '市场', label: '城市', icon: <FormatListBulletedIcon /> }}
                    //     name="Admin/CMSHospitalCity" list={HospitalCityList} create={HospitalCityCreate} edit={HospitalCityEdit} show={HospitalCityShow} />

                    
                ] : [];

                return [

                    [...adminList],

                    <CustomRoutes>
                        <Route path="/ChangePwd" element={<ChangePwd />} />
                    </CustomRoutes>,
                    // <CustomRoutes noLayout>
                    //     <Route path="/ShareOrder/:Sign" element={<ShareOrder />} />
                    // </CustomRoutes>,
                    // <CustomRoutes noLayout>
                    //     <Route path="/ShareJS/:Sign" element={<ShareJS />} />
                    // </CustomRoutes>,
                ]
            }}
        </Admin>
    );
};

export default App;