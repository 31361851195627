import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Chip } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { BaseUrl } from '../../global';
import { EditNickName, GetRealName } from './PartnerServiceChart';
//import ResetOrderNum from './Member_ResetOrderNum';

{/* 
  import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';

  <Resource name="CMSMember" list={MemberList} create={MemberCreate} edit={MemberEdit} show={MemberShow} />
  <Resource name="CMSMember" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'Member',
  },
  table: {
    id: 'id',
    M_MemberUID: 'M_MemberUID',
    M_SJMID: 'M_SJMID',
    M_MemberName: 'M_MemberName',
    M_Password: 'M_Password',
    M_NickName: 'M_NickName',
    M_Type: 'M_Type',
    M_RegIP: 'M_RegIP',
    M_RegMac: 'M_RegMac',
    M_RegWebSite: '来源',
    M_RegTime: 'M_RegTime',
    M_LoginTime: 'M_LoginTime',
    M_LoginNum: '登陆次数',
    M_ISAuto: 'M_ISAuto',
    M_BrandDrug: 'M_BrandDrug',
    M_Avatar: 'M_Avatar',
    Contract: 'Contract',
    OrderNum: 'OrderNum',
    UpTime: 'UpTime',
    IsJZK: 'IsJZK',
    M_count: 'M_count',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
  }
};

//分页列表页面
export const MemberList = () => {
  const redirect = useRedirect();//奇怪问题：这句必须保留，去掉后下面Filters里的setFilters({}, displayedFilters);会失效
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <SelectInput label="会员类型" source="MemberType" choices={[
              { id: '6', name: '患者' },
              { id: '3', name: '服务商' },
              { id: '2', name: '总服务商' },
            ]} />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" sortable={false} />
        {/* <TextField label={useTxtData.table.M_MemberUID} source="M_MemberUID" /> */}

        <FunctionField label='类别' render={({ id, M_SJMID, M_DJMID, M_Type }: any) => <>
          {/* <TextField label={useTxtData.table.M_SJMID} source="M_SJMID" /> */}
          {id != M_DJMID &&
            <>
              <ReferenceField source="M_SJMID" reference="Admin/CMSMember" link={false}>
                <Stack direction={'row'}>
                  上：<TextField source="M_MemberName" />
                  <FunctionField render={({ id, M_NickName }: any) => <EditNickName mid={id} nickName={M_NickName} >(<TextField source="M_NickName" />)</EditNickName>} />
                </Stack> 
              </ReferenceField>
              <ReferenceField source="M_DJMID" reference="Admin/CMSMember" link={false}>
                <Stack direction={'row'}>
                  总：<TextField source="M_MemberName" />
                  <FunctionField render={({ id, M_NickName }: any) => <EditNickName mid={id} nickName={M_NickName} >(<TextField source="M_NickName" />)</EditNickName>} />
                </Stack> 
              </ReferenceField>
            </>
          }
        </>} />

        <FunctionField label='会员' render={({ id, M_Type, M_Avatar }: any) => <>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {M_Avatar && <img style={{ width: 60, height: 60 }} src={BaseUrl + M_Avatar} />}
            <Stack alignItems={'start'} >
              <Chip label={M_Type == 2 ? '总服务商' : M_Type == 3 ? '服务商' : M_Type == 6 ? '患者' : 'err'} size="small" />
              {/* <br /> */}
              <Stack direction={'row'}>
                <TextField label={useTxtData.table.M_MemberName} source="M_MemberName" />
                <FunctionField render={({ id, M_NickName }: any) => <EditNickName mid={id} nickName={M_NickName} >(<TextField source="M_NickName" />)</EditNickName>} />
              </Stack>
              {M_Type == 2 && <Stack direction={'row'}>姓名：<GetRealName mid={id} /></Stack>}
            </Stack>
          </Stack>
        </>} />


        {/* <TextField label={useTxtData.table.M_RegIP} source="M_RegIP" /> */}
        {/* <TextField label={useTxtData.table.M_RegMac} source="M_RegMac" /> */}

        {/* <TextField label='来源' source="M_RegWebSite" /> */}
        <FunctionField label='来源' render={() => <>
          <TextField label={useTxtData.table.M_RegTime} source="M_RegWebSite" /><br />
          <TextField label={useTxtData.table.M_LoginTime} source="M_RegIP" /><br />
          <TextField label={useTxtData.table.M_LoginTime} source="M_RegMac" />
        </>} />

        <FunctionField label='注册/登陆' render={() => <>
          注册：<TextField label={useTxtData.table.M_RegTime} source="M_RegTime" /><br />
          登陆：<TextField label={useTxtData.table.M_LoginTime} source="M_LoginTime" />
        </>} />
        {/* <TextField label={useTxtData.table.M_RegTime} source="M_RegTime" /> */}
        {/* <TextField label={useTxtData.table.M_LoginTime} source="M_LoginTime" /> */}



        <FunctionField label='登陆/入驻' render={({ M_ISAuto }: any) => <>
          入驻方式：<span>{M_ISAuto == 2 ? '添加' : '注册'}</span><br />
          登陆次数：<TextField label={useTxtData.table.M_LoginNum} source="M_LoginNum" />
        </>} />

        {/* <TextField label={useTxtData.table.M_BrandDrug} source="M_BrandDrug" /> */}
        {/* <TextField label={useTxtData.table.Contract} source="Contract" /> */}
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.UpTime} source="UpTime" /> */}
        {/* <TextField label={useTxtData.table.IsJZK} source="IsJZK" /> */}
        {/* <TextField label={useTxtData.table.M_count} source="M_count" /> */}
        {/* <TextField label={useTxtData.table.A} source="A" /> */}
        {/* <TextField label={useTxtData.table.B} source="B" /> */}
        {/* <TextField label={useTxtData.table.C} source="C" /> */}
        {/* <TextField label={useTxtData.table.D} source="D" /> */}
        {/* <TextField label={useTxtData.table.E} source="E" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        {/* <EditButton /> */}
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.M_MemberUID} source="M_MemberUID" />
        <TextField label={useTxtData.table.M_SJMID} source="M_SJMID" />
        <TextField label={useTxtData.table.M_MemberName} source="M_MemberName" />
        {/* <TextField label={useTxtData.table.M_Password} source="M_Password" /> */}
        <TextField label={useTxtData.table.M_NickName} source="M_NickName" />
        <TextField label={useTxtData.table.M_Type} source="M_Type" />
        <TextField label={useTxtData.table.M_RegIP} source="M_RegIP" />
        <TextField label={useTxtData.table.M_RegMac} source="M_RegMac" />
        <TextField label={useTxtData.table.M_RegWebSite} source="M_RegWebSite" />

        <FunctionField label='注册/登陆' render={() => <>
          注册：<TextField label={useTxtData.table.M_RegTime} source="M_RegTime" />
          登陆：<TextField label={useTxtData.table.M_LoginTime} source="M_LoginTime" />
        </>} />

        <TextField label={useTxtData.table.M_LoginNum} source="M_LoginNum" />
        <TextField label={useTxtData.table.M_ISAuto} source="M_ISAuto" />
        <TextField label={useTxtData.table.M_BrandDrug} source="M_BrandDrug" />
        <TextField label={useTxtData.table.M_Avatar} source="M_Avatar" />
        <TextField label={useTxtData.table.Contract} source="Contract" />
        <TextField label={useTxtData.table.OrderNum} source="OrderNum" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        <TextField label={useTxtData.table.IsJZK} source="IsJZK" />
        <TextField label={useTxtData.table.M_count} source="M_count" />
        <TextField label={useTxtData.table.A} source="A" />
        <TextField label={useTxtData.table.B} source="B" />
        <TextField label={useTxtData.table.C} source="C" />
        <TextField label={useTxtData.table.D} source="D" />
        <TextField label={useTxtData.table.E} source="E" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MemberCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.M_MemberUID} source="M_MemberUID" validate={[required()]} />
      <TextInput label={useTxtData.table.M_SJMID} source="M_SJMID" validate={[required()]} />
      <TextInput label={useTxtData.table.M_MemberName} source="M_MemberName" validate={[required()]} />
      <TextInput label={useTxtData.table.M_Password} source="M_Password" validate={[required()]} />
      <TextInput label={useTxtData.table.M_NickName} source="M_NickName" validate={[required()]} />
      <TextInput label={useTxtData.table.M_Type} source="M_Type" validate={[required()]} />
      <TextInput label={useTxtData.table.M_RegIP} source="M_RegIP" validate={[required()]} />
      <TextInput label={useTxtData.table.M_RegMac} source="M_RegMac" validate={[required()]} />
      <TextInput label={useTxtData.table.M_RegWebSite} source="M_RegWebSite" validate={[required()]} />
      <TextInput label={useTxtData.table.M_RegTime} source="M_RegTime" validate={[required()]} />
      <TextInput label={useTxtData.table.M_LoginTime} source="M_LoginTime" validate={[required()]} />
      <TextInput label={useTxtData.table.M_LoginNum} source="M_LoginNum" validate={[required()]} />
      <TextInput label={useTxtData.table.M_ISAuto} source="M_ISAuto" validate={[required()]} />
      <TextInput label={useTxtData.table.M_BrandDrug} source="M_BrandDrug" validate={[required()]} />
      <TextInput label={useTxtData.table.M_Avatar} source="M_Avatar" validate={[required()]} />
      <TextInput label={useTxtData.table.Contract} source="Contract" validate={[required()]} />
      <TextInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} />
      <TextInput label={useTxtData.table.UpTime} source="UpTime" validate={[required()]} />
      <TextInput label={useTxtData.table.IsJZK} source="IsJZK" validate={[required()]} />
      <TextInput label={useTxtData.table.M_count} source="M_count" validate={[required()]} />
      <TextInput label={useTxtData.table.A} source="A" validate={[required()]} />
      <TextInput label={useTxtData.table.B} source="B" validate={[required()]} />
      <TextInput label={useTxtData.table.C} source="C" validate={[required()]} />
      <TextInput label={useTxtData.table.D} source="D" validate={[required()]} />
      <TextInput label={useTxtData.table.E} source="E" validate={[required()]} />
    </SimpleForm>
  );
}