import { Stack } from '@mui/material';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    useRedirect,
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    BulkDeleteWithConfirmButton,
    Button,
    SaveButton,
    Toolbar,
    TopToolbar,
    DeleteWithConfirmButton,
    FileInput, ImageField, ImageInput, FileField,
    ImageFieldProps,
    useRecordContext,
} from 'react-admin';
import { BaseUrl } from '../../../global';

export const UpLoadFile = (props: any) => {

    const handleImageChange = (fileObj: any) => {
        //console.log('handleImageChange', fileObj)
        //fileObj[0].
        //setPreviewPath(fileObj[0].preview);
    };
    if (props.notImg)
        return (
            <FileInput {...props} options={{ onDrop: handleImageChange }} >
                <PreviewFile source={props.source} />
            </FileInput>
        );
    else
        return (
            <ImageInput {...props} options={{ onDrop: handleImageChange }} >
                {/* <TextField source="Logo" title="LOGO" /> */}
                {/* <PreviewImage source={props.source} /> */}
                <ImageField sx={{ "& .RaImageField-image": { width: 100, height: 100 } }} source="src" />
            </ImageInput>
        );
};

export const PreviewImage = (props: any) => {
    let { record, source, label, aliPicResize } = props;
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    if (aliPicResize) {
        let _aliPicResize = aliPicResize ?? "";
        let _imgPath = record[source];
        if (_imgPath.indexOf('.aliyuncs.com') < 0) _aliPicResize = '';
        return <div aria-label={label} className={props.className}><img src={_imgPath + _aliPicResize} /></div>;
    }
    return <ImageField {...props} label={label} record={record} source={source} />
}
export const PreviewFile = ({ record, source }: any) => {
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    else {
        record = {
            [source]: record.rawFile.name
        }
    }
    return <FileField record={record} source={source} title={record[source]} />
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const FirstImageField = (props: any) => {
    const record = useRecordContext();
    let { label, source, subSource, aliPicResize } = props;
    let imgs = record[source];
    if (imgs.length > 0) {

        let _aliPicResize = aliPicResize ?? "";
        let _imgPath = subSource != undefined ? imgs[0][subSource] : imgs[0];
        if (_imgPath.indexOf('.aliyuncs.com') < 0) _aliPicResize = '';

        return <Stack aria-label={label} sx={props.sx} style={props.style} className={props.className}><img src={_imgPath + _aliPicResize} /></Stack>;
    }
    return null;
};

const default_sx = { flexDirection: 'row', gap: 1, alignItems: 'start', 'img': { height: 60, objectFit: 'contain' } };
export const AllImageField = (props: any) => {
    const record = useRecordContext();
    let { label, source, subSource, aliPicResize } = props;
    let imgs = record[source];
    if (imgs.length > 0) {
        return <Stack aria-label={label} sx={{ ...default_sx, ...props.sx }} style={props.style} className={props.className}>
            {
                imgs.map((img: any) => {
                    let _aliPicResize = aliPicResize ?? "";
                    let _imgPath = subSource != undefined ? img[subSource] : img;
                    if (_imgPath.indexOf('.aliyuncs.com') < 0) _aliPicResize = '';
                    return <img src={_imgPath + _aliPicResize} />;
                })
            }
        </Stack>;
    }
    return null;
};

export const AllImageField_Rich = (props: any) => {
    const record = useRecordContext();
    let { label, source } = props;
    let imgsHtml = record[source];
    if (imgsHtml && imgsHtml.length > 0) {
        const regexp = /<img src="(data:image\/png;base64.+?)"/gim;
        const imgs = [...imgsHtml.matchAll(regexp)];
        return <Stack aria-label={label} sx={{ ...default_sx, ...props.sx }} style={props.style} className={props.className}>
            {
                imgs.map((regImg: any) => <img src={regImg[1]} />)
            }
        </Stack>;
    }
    return null;
};