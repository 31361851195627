import { Box, Card, CardContent, CardHeader } from "@mui/material";


const Dashboard = () => (
    <Card>
        <CardHeader title="控制台" />
        <CardContent>
            <Box>
                {/* <a href={webUrl} target='_blank'>网站前端</a> */}
            </Box>
        </CardContent>
    </Card>
);


export default Dashboard;