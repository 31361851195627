import React, { useState } from "react";
import { Button, Title, useLogout } from 'react-admin';
import { apiUrl } from '../global';
import { useNotify, useRefresh, useRedirect, useAuthProvider } from 'react-admin';
import { httpClient } from '../admin/dataProvider';
import { Card, CardContent, TextField } from "@mui/material";

const ChangePwd = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const authProvider = useAuthProvider();
    const logout = useLogout();

    const [oldPwd, setOldPwd] = useState('');
    let [newPwd, setNewPwd] = useState('');
    let [newPwd2, setNew2Pwd] = useState('');

    const oldPwdHandle = (e: any) => {
        setOldPwd(e.target.value);
    }
    const newPwdHandle = (e: any) => {
        setNewPwd(e.target.value);
    }
    const newPwd2Handle = (e: any) => {
        setNew2Pwd(e.target.value);
    }

    const formSmt = async () => {
        let permissions = await authProvider.getPermissions({});
        // console.log('permissions', permissions);
        httpClient(apiUrl + `/Admin/CMSAdmin/ChangeLoginAdminPwd`, {
            method: 'POST',
            body: JSON.stringify({ oldPwd, newPwd, newPwd2 }),
        }).then(({ headers, json }) => {
            notify(json.Message);
            if (json.Code == 1) {
                logout();
            }
        });
    }

    return (
        <Card>
            <Title title="修改密码" />
            <CardContent>
                <form autoComplete="off">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 240, paddingLeft: 25, height: 200, justifyContent: 'space-between' }}>
                        <TextField required label="旧密码" type="password" value={oldPwd} onChange={oldPwdHandle} />
                        <TextField required label="新密码" type="password" value={newPwd} onChange={newPwdHandle} />
                        <TextField required label="新密码" type="password" value={newPwd2} onChange={newPwd2Handle} />
                    </div>
                    <div style={{ paddingLeft: 25, paddingTop: 25 }}>
                        <Button size="medium" variant="contained" color="primary" onClick={formSmt} label={'修改'} />
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

export default ChangePwd;