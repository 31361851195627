import { apiUrl } from "../global";

const authProvider = {
  login: ({ username, password }: any) => {
    // return Promise.resolve();
    return new Promise<string>((resolve: any, reject: any) => {
      fetch(apiUrl + "/User/AdminLogin", {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: `username=${username}&password=${password}`,
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            reject(response.statusText);
          }
          return response.json();
        })
        .then((result: any) => {
          console.log('result', result)
          if (result.Code === 1) {
            console.log("login success");
            localStorage.setItem("Authorization", result.Result.Token);
            resolve(true);
          } else {
            console.log("login fail");
            reject(result.Message);
          }
        });
    });
  },
  logout: () => {
    localStorage.removeItem("Authorization");
    return Promise.resolve();
    // return fetch(apiUrl + '/User/AdminSignOut', {
    //     method: 'POST',
    //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //     body: `token=${localStorage.getItem('token')}`
    // })
    //     .then(response => {
    //         if (response.status < 200 || response.status >= 300) {
    //             throw new Error(response.statusText);
    //         }
    //         return response.json();
    //     })
    //     .then((result) => {
    //         if (result.Code === 1) {
    //             console.log('logout success');
    //             localStorage.removeItem('token');
    //         }
    //         else {
    //             console.log('logout fail');
    //             throw new Error(result.Message);
    //         }
    //     });
  },
  checkAuth: () =>
    localStorage.getItem("Authorization") ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("Authorization");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    let token = localStorage.getItem("Authorization");
    // console.log('token', token)
    if (token) {
      // 解密JWT Token 
      let decodeToken = JSON.parse(
        decodeURIComponent(
          atob((token ?? "").split(".")[1].replace("-", "+").replace("_", "/"))
            .split("")
            .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join("")
        )
      );

      const role =
        decodeToken[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

      return Promise.resolve(role);
    }
    return Promise.reject('err');
  },
  getIdentity: () => {
    try {
      let token = localStorage.getItem("Authorization");
      // console.log('token', token)
      if (token) {
        // 解密JWT Token
        let decodeToken = JSON.parse(
          decodeURIComponent(
            atob((token ?? "").split(".")[1].replace("-", "+").replace("_", "/"))
              .split("")
              .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
              .join("")
          )
        );

        const userdata = JSON.parse(
          decodeURIComponent(
            decodeToken[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
            ])
        );
        const id =
          decodeToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
          ];
        const fullName = userdata.AdminRealName ?? userdata.AdminID;
        const avatar = userdata.Avatar;

        // 设置ID 名称 头像
        // let id = 0; let fullName = 'Lin'; let avatar = 'http://lh3.googleusercontent.com/ogw/AAEL6sgTf1rGAl2OgyTcwlbAL1KzYBwo9uvCB1rmdTM5=s64-c-mo';
        const _d = { id, fullName: `${fullName}`, avatar };
        console.log("getIdentity", _d);
        return Promise.resolve(_d);
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.reject('err');
  },
};

export default authProvider;
