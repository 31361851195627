import * as React from 'react';
import { forwardRef } from 'react';
import { useState, useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate, useRedirect, useAuthProvider, Button, Logout, useNotify } from 'react-admin';
import qs from 'qs';
import { httpClient } from '../dataProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles } from 'tss-react/mui';
import { Badge, IconButton, MenuItem, Typography, Button as MButton, Backdrop, CircularProgress } from '@mui/material';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from 'react-admin';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Stack } from '@mui/system';
import { useMutation } from 'react-query';
import { apiUrl } from '../../global';

const useStyles = makeStyles()((theme) => {
    return {
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        spacer: {
            flex: 1,
        },
    }
});

const ConfigurationMenu = forwardRef((props: any, ref: any) => {
    const logout = useLogout();
    return (
        <>
            <MenuItemLink
                ref={ref}
                to="/ChangePwd"
                primaryText={'修改密码'}
                leftIcon={<SettingsIcon />}
                onClick={props.onClick}
                sidebarIsOpen
            />
            {/* <MenuItemLink
                ref={ref}
                to="/login"
                primaryText={'退出'}
                leftIcon={<LogoutIcon />}
                onClick={() => logout()}

            /> */}
        </>
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);

const defaultProps = {
    color: 'error',
    children: <MailIcon />,
};

const MyAppBar = (props: any) => {
    const redirect = useRedirect();
    const authProvider = useAuthProvider();
    const [pendingCount, setPendingCount] = React.useState(0);
    const [permissions, setPermissions] = React.useState('');

    useEffect(() => {

    }, []);

    const { classes } = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />

            {/* {pendingCount > 0 &&
                <IconButton style={{ color: '#FFF' }} onClick={() => redirect(`/${permissions}/CMSSKOrder`)}>
                    <Badge badgeContent={pendingCount} max={999} {...defaultProps}>

                    </Badge>
                </IconButton>
            } */}
        </AppBar>
    );
};

export default MyAppBar;