import { Layout } from 'react-admin';
import MyMenu from './menu';
import MyAppBar from './appBar';


const MyLayout = (props: any) => {
    return (
        <Layout {...props} menu={MyMenu} appBar={MyAppBar}
            sx={{
                "& .MuiDrawer-root .RaSidebar-fixed": {
                    width: 'inherit'
                    // backgroundColor: 'red',
                    // position: 'initial'
                }
            }}
        />
    )
}

export default MyLayout;