// In TextField.tsx
import { Typography } from '@mui/material';
import * as React from 'react';
import { FunctionField, type FieldProps } from 'react-admin';

export const MoneyField = (props: any) => {
    const { style, source } = props;
    return (
        <FunctionField {...props} render={(record: any) => {
            const value = parseFloat(record[source ?? '']) || 0;
            return <Typography
                style={style}
                component="span"
                variant="body2"
            // className={className}
            // {...sanitizeFieldRestProps(rest)}
            >
                {value.toLocaleString('en-US')}
            </Typography>;
        }} />
    )
}