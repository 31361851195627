import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField, SelectField, DateInput, DateField } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack, Chip } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { GetFWSShowName } from './PartnerServiceChart';
//import ResetOrderNum from './MarketPtrotection_ResetOrderNum';

{/* 
  import { MarketPtrotectionList, MarketPtrotectionCreate, MarketPtrotectionEdit, MarketPtrotectionShow } from './components/MarketPtrotection';

  <Resource name="CMSMarketPtrotection" list={MarketPtrotectionList} create={MarketPtrotectionCreate} edit={MarketPtrotectionEdit} show={MarketPtrotectionShow} />
  <Resource name="CMSMarketPtrotection" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'MarketPtrotection',
  },
  table: {
    id: 'id',
    MP_Sheng: '省',
    MP_Shi: '市',
    MP_YiYuan: '医院',
    MP_KeShi: '科室',
    MP_YiYs: '医生',
    MP_DaiLi: '服务商',
    MP_ProductName: 'MP_ProductName',
    MP_ProductNameEN: 'MP_ProductNameEN',
    MP_BaohuStartTime: '开始时间',
    MP_BaohuEndTime: '结束时间',
    MP_BaohuType: '保护类型',
    MP_IsUse: '开启',
    UpTime: '记录时间',
    // OrderNum: 'OrderNum',
    // A: 'A',
    // B: 'B',
    C: '保护级别',
    // D: 'D',
    // E: 'E',
  }
};

//分页列表页面
export const MarketPtrotectionList = () => {
  const redirect = useRedirect();//奇怪问题：这句必须保留，去掉后下面Filters里的setFilters({}, displayedFilters);会失效
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />

            {/* <BooleanInput label="开启" source="Open" alwaysOn defaultValue={true} /> */}
            <SelectInput label="开启状态" source="Open" defaultValue={'1'} choices={[
              { id: '', name: '' },
              { id: '1', name: '开启' },
              { id: '0', name: '关闭' },
            ]} />

            <SelectInput label="保护类型" source="BHType" defaultValue={''} choices={[
              { id: '', name: '' },
              { id: 1, name: '正常保护' },
              { id: 2, name: '预保护' },
              { id: 3, name: '区域保护' },
            ]} />
            <SelectInput label={useTxtData.table.C} source="BHJibie" defaultValue={''} choices={[
              { id: '', name: '' },
              { id: '省', name: '省' },
              { id: '市', name: '市' },
              { id: '医院', name: '医院' },
              { id: '科室', name: '科室' },
              { id: '医生', name: '医生' },
            ]} />

            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton /><br />
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'UpTime', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <FunctionField label='区域' render={({ MP_DaiLi }: any) => <>
          {MP_DaiLi == '-1' ?
            <Chip label={'排除区域'} size="small" color='warning' />
            :
            <>
              <TextField label={useTxtData.table.MP_DaiLi} source="MP_DaiLi" sortable={false} />
              <GetFWSShowName mobile={MP_DaiLi} />
            </>
          }
        </>} />
        {/* <TextField label={useTxtData.table.MP_BaohuType} source="MP_BaohuType" sortable={false} /> */}
        <SelectField label={useTxtData.table.MP_BaohuType} source="MP_BaohuType" choices={[
          { id: 1, name: '正常保护' },
          { id: 2, name: '预保护' },
          { id: 3, name: '区域保护' },
        ]} />

        <TextField label={useTxtData.table.C} source="C" sortable={false} />
        <FunctionField label='区域' render={() => <>
          <Stack direction={'row'} spacing={1}>
            <TextField label={useTxtData.table.MP_Sheng} source="MP_Sheng" />
            <TextField label={useTxtData.table.MP_Shi} source="MP_Shi" />
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <TextField label={useTxtData.table.MP_YiYuan} source="MP_YiYuan" />
            <TextField label={useTxtData.table.MP_KeShi} source="MP_KeShi" />
            <TextField label={useTxtData.table.MP_YiYs} source="MP_YiYs" />
          </Stack>
        </>} />
        {/* <TextField label={useTxtData.table.MP_ProductName} source="MP_ProductName" /> */}
        {/* <TextField label={useTxtData.table.MP_ProductNameEN} source="MP_ProductNameEN" /> */}
        <FunctionField label='时间' render={() => <>
          <DateField label={useTxtData.table.MP_BaohuStartTime} source="MP_BaohuStartTime" />
          <br />
          <DateField label={useTxtData.table.MP_BaohuEndTime} source="MP_BaohuEndTime" />
        </>} />
        <BooleanField label={useTxtData.table.MP_IsUse} source="MP_IsUse" sortable={false} />
        <TextField label={useTxtData.table.UpTime} source="UpTime" sortable={false} />
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.A} source="A" /> */}
        {/* <TextField label={useTxtData.table.B} source="B" /> */}
        {/* <TextField label={useTxtData.table.D} source="D" /> */}
        {/* <TextField label={useTxtData.table.E} source="E" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MarketPtrotectionShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.MP_Sheng} source="MP_Sheng" />
        <TextField label={useTxtData.table.MP_Shi} source="MP_Shi" />
        <TextField label={useTxtData.table.MP_YiYuan} source="MP_YiYuan" />
        <TextField label={useTxtData.table.MP_KeShi} source="MP_KeShi" />
        <TextField label={useTxtData.table.MP_YiYs} source="MP_YiYs" />
        <TextField label={useTxtData.table.MP_DaiLi} source="MP_DaiLi" />
        <TextField label={useTxtData.table.MP_ProductName} source="MP_ProductName" />
        <TextField label={useTxtData.table.MP_ProductNameEN} source="MP_ProductNameEN" />
        <DateField label={useTxtData.table.MP_BaohuStartTime} source="MP_BaohuStartTime" />
        <DateField label={useTxtData.table.MP_BaohuEndTime} source="MP_BaohuEndTime" />
        <TextField label={useTxtData.table.MP_BaohuType} source="MP_BaohuType" />
        <TextField label={useTxtData.table.MP_IsUse} source="MP_IsUse" />
        <TextField label={useTxtData.table.UpTime} source="UpTime" />
        {/* <TextField label={useTxtData.table.OrderNum} source="OrderNum" /> */}
        {/* <TextField label={useTxtData.table.A} source="A" /> */}
        {/* <TextField label={useTxtData.table.B} source="B" /> */}
        <TextField label={useTxtData.table.C} source="C" />
        {/* <TextField label={useTxtData.table.D} source="D" /> */}
        {/* <TextField label={useTxtData.table.E} source="E" /> */}
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MarketPtrotectionEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />} mutationMode='pessimistic'>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MarketPtrotectionCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;

  const ShiSelect = () => {
    const { getValues, setValue } = useFormContext();
    const MP_Sheng = useWatch<{ MP_Sheng: string }>({ name: "MP_Sheng" });

    React.useEffect(() => {
      setValue('MP_Shi', null);
    }, [MP_Sheng])

    return (
      <ReferenceInput source="MP_Shi" reference="Admin/CMSHospitalCity" perPage={999} filter={{ inSelect: 1, inSelect_Name: MP_Sheng }}>
        <SelectInput label={useTxtData.table.MP_Shi} optionValue='Name' optionText="Name" />
      </ReferenceInput>
    )
  }

  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}

      <TextInput label={useTxtData.table.MP_DaiLi} source="MP_DaiLi" validate={[required()]} disabled={Edit} helperText={'区域保护时服务商填写-1表示排除保护'} />

      <Stack direction={'row'} spacing={1}>
        <div></div>
        {/* <TextInput label={useTxtData.table.MP_BaohuType} source="MP_BaohuType" validate={[required()]} /> */}
        <SelectInput label="保护类型" source="MP_BaohuType" defaultValue={1} validate={required()} choices={[
          { id: 1, name: '正常保护' },
          { id: 2, name: '预保护' },
          { id: 3, name: '区域保护' },
        ]} />
        {/* <TextInput label={useTxtData.table.C} source="C" validate={[required()]} /> */}
        <SelectInput label={useTxtData.table.C} source="C" defaultValue={''} validate={required()} choices={[
          { id: '', name: '' },
          { id: '省', name: '省' },
          { id: '市', name: '市' },
          { id: '医院', name: '医院' },
          { id: '科室', name: '科室' },
          { id: '医生', name: '医生' },
        ]} />
      </Stack>

      <Stack direction={'row'} spacing={1}>
        <div></div>
        <ReferenceInput source="MP_Sheng" reference="Admin/CMSHospitalSheng" perPage={999} filter={{}} >
          <SelectInput label={useTxtData.table.MP_Sheng} optionValue={'Name'} optionText={'Name'} validate={[required()]} />
        </ReferenceInput>
        <ShiSelect />
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <div></div>
        <TextInput label={useTxtData.table.MP_YiYuan} source="MP_YiYuan" validate={[]} />
        <TextInput label={useTxtData.table.MP_KeShi} source="MP_KeShi" validate={[]} />
        <TextInput label={useTxtData.table.MP_YiYs} source="MP_YiYs" validate={[]} />
      </Stack>

      {/* <TextInput label={useTxtData.table.MP_ProductName} source="MP_ProductName" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.MP_ProductNameEN} source="MP_ProductNameEN" validate={[required()]} /> */}
      <Stack direction={'row'} spacing={1}>
        <div></div>
        <DateInput label={useTxtData.table.MP_BaohuStartTime} source="MP_BaohuStartTime" validate={[required()]} />
        <DateInput label={useTxtData.table.MP_BaohuEndTime} source="MP_BaohuEndTime" validate={[required()]} />
      </Stack>

      <BooleanInput label={useTxtData.table.MP_IsUse} source="MP_IsUse" defaultValue={true} validate={[required()]} />
      {/* <TextInput label={useTxtData.table.UpTime} source="UpTime" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.OrderNum} source="OrderNum" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.A} source="A" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.B} source="B" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.D} source="D" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.E} source="E" validate={[required()]} /> */}
    </SimpleForm >
  );
}